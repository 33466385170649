<template>
  <b-card-code>
    <b-row>
      <b-col sm="6">
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            to="accounts-classification-add"
          >
            Agregar Categoría de Cuenta Contable
          </b-button>
        </b-button-group>
      </b-col>
      <b-col sm="6">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Buscar</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Buscar"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :is-loading="loading"
      :rows="rows"
      :rtl="direction"
      :fixed-header="true"
      max-height="500px"
      :sort-options="{
        enabled: true,
        initialSortBy: {field: 'categoria', type: 'asc'}
      }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <div slot="emptystate" class="text-center">
        No se encontraron datos
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status.status'">
          <b-badge :variant="statusVariant(props.row.status.status)">
            {{ props.row.status.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                :to="'/cda/accounts-classification-edit/' + props.row.idCategoria"
              >
                Editar
              </b-button>
            </b-button-group>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostrando 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','25', '50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} registros </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card-code>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BButtonGroup,
  BButton,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import environment from '@/environment'

export default {
  name: 'CentersTypesVue',
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BCardCode,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: true,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Nombre',
          field: 'categoria',
        },
        {
          label: 'Prefijo',
          field: 'prefijo',
        },
        {
          label: 'Tipo',
          field: 'type.tipo',
        },
        {
          label: 'Estado',
          field: 'status.status',
          width: '120px',
        },
        {
          label: '',
          field: 'action',
          width: '120px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Preactivo: 'light-preactivoCda',
        Inactivo: 'light-secondary',
        Activo: 'light-primary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      this.loading = true
      try {
        const resp = await this.$http.get(`${environment.uri}/caccounts/all`)
        const statuses = this.$store.getters['catalog/allStatuses']
        const types = this.$store.getters['catalog/allAccountTypes']

        if (resp && resp.data) {
          this.rows = resp.data.map(item => {
            const status = statuses.find(s => s.idStatus === item.status)
            const type = types.find(s => s.idTipo === item.idTipo)
            return {
              ...item,
              status,
              type,
            }
          }).sort((a, b) => ((a.idTipo > b.idTipo) ? 1 : -1))
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
